<template>
  <div class="result">
    <g-loading :loading="loading" />
    <van-nav-bar title="结果" v-if="source === 'h5'" />
    <div class="content">
      <!-- 题目 -->
      <div style="padding: 20px 0 0">
        <Item
          v-for="(obj, index) in resultList"
          :key="index"
          :data="obj"
          :role="role"
        />
      </div>
      <!-- 结果 -->
      <ul class="result-content">
        <li class="item-content" v-for="(val, index) in list" :key="index">
          <div class="small-title padding-8">
            <div class="line" />
            <div class="text line-text">结果</div>
          </div>
          <div class="result-padding">
            <RichText :html="val.trigger"></RichText>
          </div>
          <div class="small-title">
            <div class="line" />
            <div class="text line-text">建议</div>
          </div>
          <div class="result-padding">
            <RichText :html="val.result"></RichText>
          </div>
        </li>
      </ul>
      <div class="desc" v-if="resultData.desc">{{ resultData.desc }}</div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { combinationData } from "../../utils/format"
import Item from "../task/Item2"
import RichText from "../../components/RichText"

export default {
  data() {
    return {
      value: null,
      selectI: {},
      messageHandler: null,
      registered: true,
      role: undefined,
    }
  },
  components: {
    RichText,
    Item,
  },
  created() {
    this.initdata()
    this.role = this.$route.query.role
  },
  computed: {
    ...mapState("commonData", {
      source: (state) => state.source,
    }),
    ...mapState("task", {
      resultList: (state) => state.resultList,
      resultData: (state) => state.resultData,
      loading: (state) => state.loading,
    }),
    list() {
      return this.combinationData(this.resultData.script_result || [])
    },
  },
  methods: {
    combinationData,
    ...mapActions("task", ["get_result_detail"]),
    initdata() {
      this.get_result_detail(this.$route.query.id)
    },
  },
}
</script>
<style scoped>
.line-text {
  color: #3c82ff !important;
}
.border-rad {
  border-radius: 4px;
}
.padding-8 {
  background: none !important;
  padding: 0 0 8px 0 !important;
}
.result {
  width: 100%;
  height: 100%;
  background: rgba(242, 242, 242, 1);
  display: flex;
  flex-direction: column;
}
.result-content {
  margin-top: 20px;
  margin-bottom: 20px;
}
.item-content {
  /*display: flex;*/
  align-items: center;
  position: relative;
  background: #fff;
  margin: 0px 10px 8px;
  border-radius: 4px;
  padding: 20px 10px 12px 10px;
  & .left {
    flex: 1;
    & .item-result {
      line-height: 28px;
      color: #333;
      font-size: 16px;
      /* overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
    }
    & .item-next {
      font-size: 13px;
      font-weight: 400;
      color: rgba(60, 130, 255, 1);
      /*line-height:20px;*/
      /*margin-top: 8px;*/
    }
  }
}
.result-padding {
  padding-left: 26px;
}
.content {
  flex: 1;
  overflow: auto;
  padding-bottom: 20px;
}
.small-title {
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  padding: 12px 0 12px 0;
  & .line {
    width: 4px;
    height: 15px;
    margin-left: 10px;
    background: url("../../assets/img/result-line.png") center center no-repeat;
    background-size: 100% 100%;
  }
  & .text {
    flex: 1;
    padding-left: 12px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    line-height: 16px;
  }
}
.btn {
  width: 80%;
  height: 36px;
  background: #1890ff;
  margin: 10px auto 0;
  border-radius: 18px;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
}
.desc {
  background: #fff;
  padding: 10px 10px;
  font-size: 14px;
  margin: 0px 10px 18px;
}
</style>
