<template>
  <div class="itemView">
    <!-- 标题-->
    <!-- 为医生的时候展示题目类型 -->
    <div
      v-if="
        role == 3 &&
        questionnaire_types.length &&
        questionnaire_types.includes('、')
      "
      style="text-align: right; margin: 0 0 10px 0"
    >
      <span class="ques-type">
        {{ questionnaire_types }}
      </span>
    </div>
    <div v-if="data.name && data.type !== 5" class="name">
      <i class="img2" v-if="data.question.isRequired">*</i>
      <!-- <img class="img1" v-if="data.isShowAi&&data.question.isEdit" src="../../assets/img/ai.png"/> -->
      <span class="span-name">{{ data.type !== 9 ? data.name : "" }}</span>
      <!-- 为医生的时候展示题目类型 -->
      <span
        class="ques-type"
        v-if="
          role == 3 &&
          questionnaire_types.length &&
          !questionnaire_types.includes('、')
        "
        >{{ questionnaire_types }}</span
      >
    </div>

    <!-- 单选 ，多选-->
    <div v-if="data.type === 1 || data.type === 2" class="formLine">
      <SelectQuetion v-model="data.value" :data="data" :type="2" />
    </div>
    <!-- 数字填空, 纯填空 -->
    <div v-if="data.type === 4" class="formLine">
      <NumberInputQeution v-model="data.value" :type="3" :data="data" />
    </div>
    <!-- 纯填空 -->
    <div v-if="data.type === 3" class="formLine">
      <TextInputQeution v-model="data.value" :type="3" :data="data" />
    </div>
    <!-- 时间 -->
    <div class="formLine" v-if="data.type === 7">
      <TimeQuetion v-model="data.value" :type="3" :data="data" />
    </div>
    <!-- 药品 -->
    <div v-if="data.type === 8" style="margin: 0 18px">
      <MedicinceQuetion
        :data="data"
        v-model="data.value"
        :type="2"
        @savePosition="savePosition"
      />
    </div>
    <!-- 电子签名 -->
    <div v-if="data.type === 6" class="common">
      <Sign v-model="data.value" :disabled="true" />
    </div>
    <!-- 文本 -->
    <div class="other" v-if="data.type === 5">
      <RichText :data="data" />
    </div>
    <!-- 量表题 -->
    <div class="common" v-if="data.type === 9">
      <Slider :data="data" v-model="data.value" :type="2" />
    </div>
    <!-- 步进器题 -->
    <div class="formLine" v-if="data.type === 10">
      <step
        v-model="data.value"
        :min="data.question.options[2]"
        :unit="data.question.postfix"
        :disabled="true"
        :max="data.question.options[1]"
      ></step>
    </div>
    <div>
      <div class="common" v-if="data.type === 11">
        <TakePhoto :item="data" :disabled="true" />
      </div>
      <!-- 血压 -->
      <div v-if="data.type === 15" class="formLine">
        <BloodPressureQuestion v-model="data.value" :data="data" :type="3" />
      </div>
    </div>
  </div>
</template>
<script>
import Sign from "../questions/sign-quetion"
import Slider from "../questions/slider-question"
import Step from "../../components/Step"
import TakePhoto from "../questions/takePhoto"
import RichText from "../questions/html-quetion"
import SelectQuetion from "../questions/choice-question"
import NumberInputQeution from "../questions/number-input-quetion"
import TextInputQeution from "../questions/text-input-quetion"
import TimeQuetion from "../questions/time-quetion"
import MedicinceQuetion from "../questions/medicine-quetion"
import BloodPressureQuestion from "../questions/bloodPressure-question"
import { questionnaire_types } from "./config"

export default {
  props: {
    data: {
      default: () => {},
      type: Object,
    },
    role: {
      type: String,
      default: "",
    },
  },
  computed: {
    questionnaire_types() {
      return (
        this.data.questionnaire_type_enums
          ?.map((el) => questionnaire_types[el])
          ?.join("、") || ""
      )
    },
  },
  components: {
    Step,
    Slider,
    TakePhoto,
    RichText,
    Sign,
    SelectQuetion,
    NumberInputQeution,
    TextInputQeution,
    TimeQuetion,
    MedicinceQuetion,
    BloodPressureQuestion,
  },
  methods: {
    savePosition() {},
  },
}
</script>
<style scoped>
/* ios兼容 */
input[disabled],
input:disabled,
input.disabled {
  color: #333;
  -webkit-text-fill-color: #333;
  -webkit-opacity: 1;
  opacity: 1;
}
.itemView {
  font-size: 16px;
  padding-bottom: 28px;
  &:last-of-type {
    &:after {
      display: none;
    }
  }
}
.name {
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  align-items: center;
  padding: 0 16px;
  display: flex;
  align-items: center;
}
.formLine {
  display: flex;
  align-items: center;
  min-height: 44px;
  margin: 0 36px;
  padding: 16px 9px;
  border-bottom: 1px dotted #999999;
}
.img1 {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}
.img2 {
  width: 6px;
  height: 6px;
  color: rgba(252, 73, 32, 1);
  line-height: 12px;
  margin-left: -3px;
}
.span-name {
  margin-left: 6px;
  flex: 1;
}
.ques-type {
  white-space: nowrap;
  align-self: flex-start;
  padding: 4px 16px;
  background: rgba(253, 163, 61, 0.2);
  color: #fda33d;
  font-weight: 400;
  font-size: 14px;
}
.common {
  margin: 0 36px;
  padding: 16px 9px;
  border-bottom: 1px dotted #999999;
}
.other {
  margin: 0 36px;
  padding: 0px 9px 16px;
  border-bottom: 1px dotted #999999;
}
</style>
